<template>
    <v-theme-provider
        :theme="theme"
    >
        <v-app-bar
            color="navbg"
            class="pa-4"
            extended
            :extension-height="0"
        >
            <v-container>
                <v-row>
                    <v-col
                        cols="6"
                    >
                        <v-img
                            class="mt-0"
                            :src="logo"
                            max-width="180"
                            max-height="150"
                        />
                    </v-col>
                    <v-spacer />
                    <v-col
                        cols="5"
                        class="text-right pr-12"
                        align-self="center"
                    >
                        <div class="text-navtext text-h4 font-weight-regular">
                            <slot name="title">
                                accadis <span class="font-weight-bold">Infoanzeige</span>
                            </slot>
                        </div>
                    </v-col>
                    <v-img
                        :src="image"
                        class="mr-2 mt-3"
                        width="61"
                        height="61"
                    />
                </v-row>
            </v-container>
        </v-app-bar>
    </v-theme-provider>
</template>

<script lang="ts" setup>
import { useTheme } from 'vuetify';

const props = defineProps({
    theme: { type: String, required: false, default: () => 'accadisHSTheme' },
    image: { type: String, required: true },
});

const theme = computed(() => props.theme);
const image = computed(() => props.image);

const currentTheme = useTheme();
const logo = computed(() => currentTheme.current.value?.variables['logo'].toString());
</script>
